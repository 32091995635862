import styles from '../styles/Home.module.css'
import {Box, Typography} from "@mui/material";
import TextWithSideImage from "../components/shared/TextWithSideImage";
import HomeIntro from "../components/sections/HomeIntro";
import StyledSideImage from "../components/shared/StyledSideImage";
import Testimonials from "../components/shared/Testimonials";
import Link from "next/link";
import Head from "next/head";
import seo from "../constants/seo.json";
import Image from "next/image";

export default function Home() {
    const meta = seo.home;

    return (
        <div className={styles.container}>

            <Head>
                <title>{meta.title}</title>
                <meta name="description" content={meta.description}/>
                <meta name="keywords" content={meta.keywords}/>
            </Head>

            {/*<Box className={styles.bigBlueBackground} />*/}

            <HomeIntro/>

            <TextWithSideImage textDirection={'right'}
                               primaryText={'Voice Blast'}
                               text={'We have saved many lives, thanks to Insta Alert service. Earlier I used to use a paging system and it was a nightmare to get the right firemen and EMTs to the target location.'}
                               image={'/images/use-cases/voice-blast-use.png'}
                               imageAlt={'Voice Blast Use Case'}
                               imageWidth={594}
                               imageHeight={594}
            />

            <TextWithSideImage textDirection={'left'}
                               primaryText={'SMS Blast'}
                               text={'It is incredible how fast this Insta Alert service works. I use speed dial for calling in on my mobile and within seconds I am talking to all of my paramedics.'}
                               image={'/images/use-cases/sms-blast-use.png'}
                               imageAlt={'SMS Blast Use Case'}
                               imageWidth={624}
                               imageHeight={449}
            />

            <TextWithSideImage textDirection={'right'}
                               primaryText={'Email Blast'}
                               text={'Insta Alert service is ultra-reliable. Using this service we can get all the decision makers into a quick conference which otherwise could take hours. This is a ‘must have’ service for an emergency prone environment like ours.'}
                               image={'/images/use-cases/email-blast-use.png'}
                               imageAlt={'Email Blast Use Case'}
                               imageWidth={566}
                               imageHeight={398}
            />

            <TextWithSideImage textDirection={'left'}
                               primaryText={'Social Network Blast'}
                               text={'We operate in an environment in which serious injuries can occur at any time. Saving minutes and seconds can make a difference between if someone lives or dies. That’s how crucial is Insta Alert to us.'}
                               image={'/images/use-cases/social-blast-use.png'}
                               imageAlt={'Social Network Blast Use Case'}
                               imageWidth={572}
                               imageHeight={506}
            />

            <TextWithSideImage textDirection={'right'}
                               primaryText={'Desktop and Mobile Push Notifications'}
                               text={'We operate in an environment in which serious injuries can occur at any time. Saving minutes and seconds can make a difference between if someone lives or dies. That’s how crucial is Insta Alert to us.'}
                               image={'/images/use-cases/push-notif-blast-use.png'}
                               imageAlt={'Desktop and Mobile Push Notifications Use Case'}
                               imageWidth={570}
                               imageHeight={344}
            />

            <TextWithSideImage textDirection={'left'}
                               primaryText={'Marquee Display Notification'}
                               text={'We operate in an environment in which serious injuries can occur at any time. Saving minutes and seconds can make a difference between if someone lives or dies. That’s how crucial is Insta Alert to us.'}
                               image={'/images/use-cases/marquee-display-blast.png'}
                               imageAlt={'Marquee Display Notification Use Case'}
                               imageWidth={607}
                               imageHeight={368}
            />

            <Testimonials/>

            <Box className={styles.getStartedContainer}>
                <Typography variant={"h2"} color={"primary"}>Let's Get Started</Typography>
                <Typography variant={"h4"}>
                    <Link href="/contact">Contact Us Now!</Link>
                </Typography>
            </Box>
        </div>
    )
}
