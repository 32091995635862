import {Box, Typography, Button} from "@mui/material";
import styles from '../../styles/TextWithSideImage.module.css'
import PageContainer from "../layouts/PageContainer";
import Image from "next/image";
import Link from "next/link";
import toSnakeCase from "../../utility/toSnakeCase";

export default function TextWithSideImage({
                                              textDirection,
                                              primaryText,
                                              text,
                                              secondaryText,
                                              image,
                                              color = "white",
                                              imageWidth,
                                              imageAlt,
                                              imageHeight
                                          }) {
    return (
        <Box className={styles.container} style={{background: color}}>
            <PageContainer>
                <Box className={styles.innerContainer}
                     style={{flexDirection: (textDirection === 'left' ? 'row' : 'row-reverse')}}>
                    <Box className={styles.text}>
                        <Typography component="h2" className={styles.primaryText} color={"primary"}>
                            {primaryText}
                        </Typography>
                        <Typography component="div" className={styles.contentText}>
                            {text}
                        </Typography>
                    </Box>
                    <Box className={styles.image}>
                        <Image priority={true} src={image} width={imageWidth} height={imageHeight}
                               alt={imageAlt || primaryText}/>
                    </Box>
                </Box>
            </PageContainer>
        </Box>
    )
}
