import React, {useEffect, useRef, useState} from "react";
import {Box, Button, Fade, List, ListItem, Slide, Typography, useTheme} from "@mui/material";
import PageContainer from "../layouts/PageContainer";
import styles from '../../styles/Home.module.css'
import Link from "next/link";
import {useIntl} from "react-intl";
import clsx from "clsx";

export default function HomeIntro({interval = 5000}) {
    const intl = useIntl();
    const theme = useTheme();

    const [currentSlide, setCurrentSlide] = useState(0);
    const carouselItems = [
        {
            menuItem: 'Voice Blast',
            title: 'Voice Blast',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.',
            image: '/images/carousel/slide1.png',
            url: '/features'
        },
        {
            menuItem: 'SMS Blast',
            title: 'SMS Blast',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.',
            image: '/images/carousel/slide2.png',
            url: '/features'
        },
        {
            menuItem: 'Email Blast',
            title: 'Email Blast',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.',
            image: '/images/carousel/slide3.png',
            url: '/features'
        },
        {
            menuItem: 'Social Network Blast',
            title: 'Social Network Blast',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.',
            image: '/images/carousel/slide4.png',
            url: '/features'
        },
        {
            menuItem: 'Desktop & Mobile Push Notification Blast',
            title: 'Desktop & Mobile Push Notification',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.',
            image: '/images/carousel/slide5.png',
            url: '/features'
        },
        {
            menuItem: 'Marquee Display Notifications',
            title: 'Marquee Display Notification',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.',
            image: '/images/carousel/slide6.png',
            url: '/features'
        }
    ];

    const handleSlideSelector = (index) => {
        setCurrentSlide(index);
    }

    useEffect(() => {
        const slideTimer = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % carouselItems.length);
        }, interval);

        return () => {
            clearInterval(slideTimer);
        };
    }, [interval, currentSlide]);

    return (
        <Box className={styles.headerContainer}>
            {carouselItems.map((item, index) => (
                <Box className={styles.headerInto}>
                    <Fade in={(currentSlide === index)} timeout={2000}>
                        <img key={index}
                             className={styles.headerBackground}
                             src={item.image}
                             alt={item.title}
                        />
                    </Fade>

                    <PageContainer>
                        <Box className={styles.headerInfoInner}>
                            <Slide direction={"right"} in={(currentSlide === index)}
                                   easing={theme.transitions.easing.easeInOut} timeout={700}>
                                <Box className={styles.headerInfoLeft}>
                                    {/*<Typography className={styles.mainText} variant={"h1"} component={"h1"}>*/}
                                    {/*    Multi Modal Mass <br/><b>Notification</b>*/}
                                    {/*</Typography>*/}
                                    <Typography className={styles.mainText} color={"primary"} variant={"h1"}
                                                component={"h1"}>
                                        {item.title}
                                    </Typography>
                                    <Typography className={styles.featureSubject} color={"primary"} variant={"h2"}
                                                component={"h2"}>
                                        Multi Modal Mass Notification
                                    </Typography>
                                    <Typography className={styles.featureDescription} variant={"h3"}
                                                component={"h4"}>
                                        {item.description}
                                    </Typography>
                                </Box>
                            </Slide>

                            <Box className={styles.headerSlideNavigation}>
                                {carouselItems.map((item, index) => (
                                    <Box onClick={() => {
                                        handleSlideSelector(index)
                                    }}
                                         className={clsx(styles.headerSlideNavigationBall, (currentSlide === index) && styles.headerSlideNavigationBallCurrent)}/>
                                ))}
                            </Box>

                            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                <Button variant={"contained"}
                                        color={"primary"}
                                        component={Link}
                                        href={item.url}
                                        title={'Features list for product'}
                                        className={styles.learnMoreBtn}>
                                    {intl.formatMessage({id: "page.learn_more"})}
                                </Button>
                            </Box>
                        </Box>
                    </PageContainer>
                </Box>
            ))}


            <Box className={styles.headerSlideNavigationMobile}>
                {carouselItems.map((item, index) => (
                    <Box onClick={() => {
                        handleSlideSelector(index)
                    }}
                         className={clsx(styles.headerSlideNavigationBall, (currentSlide === index) && styles.headerSlideNavigationBallCurrent)}/>
                ))}
            </Box>

        </Box>
    )
}

